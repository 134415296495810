import React from 'react';
import Select from 'react-select';

const Estados = ({ estados, selEstados, setSelEstados, onChangeEstados }) => {
  return (
    <>
      <div className="row justify-content-center mb-5vh">
        <span className="text-center titulo-selector">Elige un estado: </span>
        <Select
          className="col-md-6 z-index-9999"
          placeholder="Selecciona una opción"
          value={selEstados}
          options={estados}
          onChange={onChangeEstados}
          getOptionLabel={(x) => x.name}
          getOptionValue={(x) => x.id}
        />
      </div>
    </>
  );
};

export default Estados;
