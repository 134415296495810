import React, { useState, useEffect } from "react";
import { clienteAxiosMobile } from "../Utils/axios.js";
import { clienteAxios } from "../Utils/axios.js";
import "./Productos.css";
import MenuProductos from "./MenuProductos";
import Clasificacion from "./Clasificacion.js";
const Productos = () => {
  const [wooProductos, guardarWooProductos] = useState([]);
  const [wooCategorias, guardarWooCategorias] = useState([]);
  const [wooEtiquetas, guardarWooEtiquetas] = useState([]);

  const userName = process.env.REACT_APP_USERNAME;
  const pass = process.env.REACT_APP_PASS;
  const token = Buffer.from(`${userName}:${pass}`, 'utf8').toString('base64');

  useEffect(() => {
    const obtenerMenuWOO = async () => {
      await clienteAxios("wp-json/wc/v3/products?per_page=50", {
        headers: {
          Authorization: `Basic ${token}`
        }
      }).then((respuesta) => {
        guardarWooProductos(respuesta.data);
      })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerMenuWOO();

    const obtenerCategoriasWOO = async () => {
      await clienteAxios("wp-json/wc/v3/products/categories?orderby=id&order=asc", {
        headers: {
          Authorization: `Basic ${token}`
        }
      }).then((respuesta) => {
        const omitirCategoria = respuesta.data.filter(categoria => categoria.id != 616);
        guardarWooCategorias(omitirCategoria);
      })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerCategoriasWOO();

    const obtenerTagsWOO = async () => {
      await clienteAxios("wp-json/wc/v3/products/tags?per_page=50", {
        headers: {
          Authorization: `Basic ${token}`
        }
      }).then((respuesta) => {
        guardarWooEtiquetas(respuesta.data);
      })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerTagsWOO();

  }, []);

  return (
    <>

      {wooProductos.length > 0 ? (
        <Clasificacion
          productos={wooProductos}
          categorias={wooCategorias}
          etiquetas={wooEtiquetas}
        />
      ) : null


      }

    </>
  );
};

export default Productos;
