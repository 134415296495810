import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Ciudades = ({ ciudades, onClickCiudad}) => {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="text-center titulo-selector mb-5vh">Elige una ciudad</div>
      <div className="row justify-content-center mb-125 ciudades-web">
        {ciudades.map((ciudad) => (
          <button
            className="col-lg-1 mr-05vw button-ciudad"
            onClick={() => onClickCiudad(ciudad.id)}
          >
            {ciudad.name}
          </button>
        ))}
      </div>
      <div className="row justify-content-center mb-125 ciudades-mobile">
        <Slider {...settings}>
          {ciudades.map((ciudad) => (
            <button
              className="col-lg-1 mr-05vw button-ciudad"
              onClick={() => onClickCiudad(ciudad.id)}
            >
              {ciudad.name}
            </button>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Ciudades;
