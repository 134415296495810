import React from 'react';

const Popup = ({ cafeteria, setShow }) => {
  const closePopup = () => {
    setShow(false);
  };
  return (
    <>
      <div className="popup-contenedor">
        <div className="modal-contenedor-cafeteria">
          <button className="btnCerrar" onClick={closePopup}>
            X
          </button>
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <img src={cafeteria.venueimage} alt="" />
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 titulo-popup mb-5vh">
                  <span>{cafeteria.title}</span>
                </div>
              </div>
              <div className="row horario-web">
                <div className="col-lg-3 titulo-direccion mb-5vh">
                  <span>Horario: </span>
                </div>
                <div className="col-lg-9 descripcion-direccion mb-5vh">
                  <div>
                    Lunes a Viernes{' '}
                    <span className="span-horario">
                      {cafeteria.mondaytofriday}
                    </span>
                  </div>
                  <div>
                    Sabado{' '}
                    <span className="span-horario">{cafeteria.saturdat}</span>
                  </div>
                  <div>
                    Domingo{' '}
                    <span className="span-horario">{cafeteria.sunday}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 titulo-direccion mb-5vh">
                  <span>Dirección: </span>
                </div>
                <div className="col-lg-9 descripcion-direccion mb-5vh">
                  <span>{cafeteria.direccion}</span>
                </div>
              </div>
              <div className="row horario-mobile">
                <div className="col-lg-3 titulo-direccion mb-5vh">
                  <span>Horario: </span>
                </div>
                <div className="col-lg-9 descripcion-direccion mb-5vh">
                  <div className="espacio-horario">
                    Lunes a Viernes{' '}
                    <span className="span-horario">
                      {cafeteria.mondaytofriday}
                    </span>
                  </div>
                  <div className="espacio-horario">
                    Sabado{' '}
                    <span className="span-horario">{cafeteria.saturdat}</span>
                  </div>
                  <div className="espacio-horario">
                    Domingo{' '}
                    <span className="span-horario">{cafeteria.sunday}</span>
                  </div>
                </div>
              </div>
              <div className="text-position contendor-btn-llegar">
                <a
                  href={`https://www.google.com/maps/search/${cafeteria.latitude},${cafeteria.longitud}`}
                  className="link-como-llegar"
                  target="_blank"
                  rel="noreferrer"
                >
                  Como llegar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
