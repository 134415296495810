import React from "react";
import "./Error.css";
const Error = ({ mensaje, guardarError }) => {
  const cerrar = () => {
    guardarError(false);
  };

  return (
    <>
      <div className="error-contenedor">
        <div className="error-mensaje">{mensaje}</div>
        <div className="error-contenedor-boton">
          <input
            type="button"
            value="Cerrar"
            onClick={cerrar}
            className="error-boton"
          />
        </div>
      </div>
    </>
  );
};

export default Error;
