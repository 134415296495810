import React, { useState, useEffect } from "react";
import { clienteAxiosReyes } from "../../Utils/axios.js";
import { Decrypt, Encrypt } from "../../Utils/EncryptDecrypt.js";
import Bienvenida from "./Bienvenida";
import AgregarParticipantes from "../components/AgregarParticipante";
import Error from "../components/Error";
import "./Login.css";
import Informacion from "./Informacion";

const LoginReyes = () => {
  const [correo, guardarCorreo] = useState(null);
  const [contrasena, guardarContrasena] = useState(null);
  const [usuarioslogin, guardarUsuarioslogin] = useState([]);
  const [token, guardarToken] = useState(null);
  const [error, guardarError] = useState(false);
  const [confirmar, guardarConfirmar] = useState(false);
  const [mensaje, guardarMensaje] = useState("");
  const [firsttime, guardarFirsttime] = useState(false);
  const [terminos, guardarTerminos] = useState(false);
  const [usuarioExistente, guardarUsuarioExistente] = useState("");
  const [anio, setAnio] = useState(0);


  useEffect(() => {
    const verificarToken = async () => {
      const usuario = Decrypt("usuario");
      const { Nombre } = usuario ?? {};

      if (Nombre) {
        guardarUsuarioExistente(Nombre);
      } else {
        guardarUsuarioExistente('');
      }
    }
    verificarToken();
    const obtenerAñoActual = () =>{
      const fecha = new Date();
      const añoActual = fecha.getFullYear();
      setAnio(añoActual);
      console.log(añoActual);
    }
    obtenerAñoActual();
  }, []);


  const definirCorreo = (e) => {
    guardarCorreo(e.target.value);
  };

  const definirContrasena = (e) => {
    guardarContrasena(e.target.value);
  };

  const ingresar = async (e) => {
    // Encrypt("usuario", {Nombre:"Juan David"});
    // window.location.href = "felicidades/";
    e.preventDefault();
    // guardarMostrarbienvenida(true);
    if (
      (correo !== "" || correo != null) &&
      contrasena !== "" &&
      contrasena != null
    ) {
      let data = {
        user: correo,
        password: contrasena,
      };
      const respuesta = await clienteAxiosReyes
        .post("/api/auth/get_token/", data)
        .then((respuesta) => {
          guardarUsuarioslogin(respuesta.data.user);
          guardarToken(respuesta.data.token);
          Encrypt("token", respuesta.data.token);
          Encrypt("usuario", respuesta.data.user);
          Encrypt("nombre", respuesta.data.user.Nombre);
          validarInscripcion();
        })
        .catch((error) => {
          console.log(
            "No puede iniciar sesión con las credenciales proporcionadas."
          );
          guardarError(true);
          guardarMensaje(
            "No puede iniciar sesión con las credenciales proporcionadas."
          );
        });

    }
    else {
      console.log("Falta capturar información");
      guardarError(true);
      guardarMensaje("Todos los campos deben ser proporcionados.");

    }
  };
  const validarInscripcion = async () => {
    await clienteAxiosReyes("/api/reyes/2023/validate/", {
      headers: {
        Authorization: "Bearer " + Decrypt("token"),
      },
    })
      .then((respuesta) => {



        if (!respuesta.data.is_in_program) {
          if (!terminos) {
            guardarError(true);
            guardarMensaje("Confirme los términos y condiciones.");
          } else {
            guardarConfirmar(true);
            guardarMensaje(`¿Quieres participar para ser el  próximo Rey de CAFFENIO ${anio}?`);   
          }

        } else {
          // window.location.href = "felicidades/";
          window.location.href = "https://caffenio.com/reyesdecaffenio/historial";
        }
      })
      .catch((error) => {
        console.log(
          "No puede iniciar sesión con las credenciales proporcionadas."
        );
      });
  };

  const onChangeTerminos = (e) => {
    guardarTerminos(e.target.checked);
  }

  return (
    <>

      {confirmar && <AgregarParticipantes mensaje={mensaje} confirmar={confirmar} guardarConfirmar={guardarConfirmar} />}
      {error && <Error mensaje={mensaje} guardarError={guardarError} />}
      {/* {mostrarbienvenida && (
          <Informacion
            guardarMostrarbienvenida={guardarMostrarbienvenida}
            usuarioslogin={usuarioslogin}
            guardarFirsttime={guardarFirsttime}
          />
        )} */}

      {usuarioExistente == null || usuarioExistente == '' ?
        <>
          <form class="form-login" onSubmit={ingresar}>

            <h2 className="login-title">¿Quieres ser un Rey de CAFFENIO?</h2>

            <p className="login-participa">REGÍSTRATE con tu cuenta MI CAFFENIO para participar</p>
            <label htmlFor="" className="verifica_tus_datos_txt">Verifica tus datos:</label>
            <div class="form-group login-grupo">
              
              <label htmlFor="" class="fs-1">
                Correo, teléfono, número de tarjeta:
              </label>
              <input
                type="text"
                class="form-control login-input"
                placeholder="Correo"
                name="correo"
                id="correo"
                onChange={definirCorreo}
              />
              <label htmlFor="" class="label-title fs-1">
                Contraseña:
              </label>
              <input
                type="password"
                class="form-control login-input"
                placeholder="Contraseña"
                name="password"
                id="password"
                onChange={definirContrasena}
              />

              <div className="pt-5 text-left">
                <input type="checkbox" /*name="acept" id="acept"*/ onChange={onChangeTerminos} />
                <span className="terms">Acepto los
                  <a href="https://caffenio.com/reyesdecaffenio/teminos-y-condiciones/" target="_blank"><u> términos y condiciones</u>
                  </a> para participar Reyes de CAFFENIO {anio}</span>
              </div>


              <div class="pt-5">
                <a
                  class="link-recuperar fslink-1"
                  href="https://mi.caffenio.com/Account/ForgotPassword"
                  target="_blank"
                >
                  ¿Olvidaste tu contraseña?
                </a>
              </div>
              <div class="pt-2 fslink-1">
                <span>¿Aún no tienes una cuenta? </span>
                <a
                  class="link-recuperar"
                  href="https://mi.caffenio.com/Select/RegisterType"
                  target="_blank"
                >
                  Regístrate
                </a>
              </div>
              <div class="login-align-end">
                <input
                  id="btnIniciasesion"
                  type="submit"
                  class="form-control acceder-boton fsbutton-1"
                  value="Participar"
                />
              </div>
            </div>
          </form>

        </>


        : //Si hay un usuario motrará este parte

        <div className="form-login text-center">
          <img className="logo-form" src="https://caffenio.com/wp-content/uploads/2024/08/Grupo-21310-1.png" alt="Logo" />

          <h2 className="name-form">¡Hola, {usuarioExistente}!</h2>
          <p className="text-form">Ya estas participando para ser el próximo rey de Caffenio.</p>
          <p className="release-date-form">Conoce las bases a partir del 01 de septiembre del {anio}.</p>
        </div>




      }


    </>
  );
};

export default LoginReyes;
