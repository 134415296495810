import React, { useState, useEffect } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer
} from "react-leaflet";
import { MarkerIcon } from "./react-leaflet-icon.js";
import "./App.css";
import Drive from "./components/Drive";
import Menu from "./components/Menu";
import data from "../Data/db.json";
import { clienteAxiosMobile } from "../Utils/axios.js";
import "leaflet";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const ACCESS_TOKEN =
  "pk.eyJ1IjoiY2h1YXZuIiwiYSI6ImNsZGV0MXJvNTBoMDAzbm9kNXgyMmlmeTAifQ.91mSKH_j71E2nPPrqt3Pqw";

const URL = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${ACCESS_TOKEN}`;
const ATTRIBUTION =
  'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';

const coordenadas_fijas = [29.12146723406434, -110.9836789619031];

function App() {
  const [mapa, guardarMapa] = useState(null);
  const [coordenadas, guardarCoordenadas] = useState([]);
  const [zoom, guardarZoom] = useState(16);
  const [municipios, guardarMunicipios] = useState([]);
  const [estados, guardarEstados] = useState([]);
  const [municipiosrespaldo, guardarMunicipiosrespaldo] = useState([]);
  const [drives, guardarDrives] = useState([]);
  const [bdrives, guardarBdrives] = useState([]);
  const [busqueda, guardarBusqueda] = useState(null);
  const [drivesFinal, guardarDrivesFinal] = useState([]);
  const [ubicaciones, guardarUbicaciones] = useState([]);

  useEffect(() => {

    const obtenerUbicaciones = async () => {
      await clienteAxiosMobile("v2/venues")
        .then((respuesta) => {
          const data = respuesta.data.reduce((r, { ...object }) => {
            let nombre = removeAccents(object.City.trim().toLowerCase()) + ', ' + removeAccents(object.State.trim().toLowerCase());
            let temp = r.find(o => o.nombre === nombre);
            if (!temp) r.push(temp = { nombre, drives: [] });
            temp.drives.push(object);
            return r;
          }, []);
          console.log('Mis datos ', data);
          guardarUbicaciones(respuesta.data);
          guardarMunicipios(data);
          // const arr = [];
          // arr.push(estados(respuesta.data));
          // guardarEstados(arr[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerUbicaciones();

    const removeAccents = (str) => {
      const text = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return text.charAt(0).toUpperCase() + text.slice(1);
    }


    const estados = (ubicaciones) => {
      const personasMap = ubicaciones.map(ubicacion => {
        return [ubicacion.State.trim().toLowerCase(), ubicacion.State]
      });

      return [...new Map(personasMap).values()];
    }
    console.log('Funcion Estados ');


    const posicionActual = () => {
      guardarCoordenadas(coordenadas_fijas);
    };
    posicionActual();

  }, []);

  const ChangeView = (center, zoom) => {
    mapa.setView(center, zoom);
  }




  const obtenerBusqueda = (e) => {
    e.preventDefault();
    guardarBusqueda(([e.target.name] = e.target.value));
    guardarBdrives([]);
    guardarDrivesFinal(municipiosrespaldo);
  };

  const buscar = () => {
    guardarBdrives([]);
    let resultadoBusqueda = [];
    let resultadoBusquedaDrive = [];
    resultadoBusqueda = municipios.filter((municipio) => municipio.nombre === busqueda);
    if (resultadoBusqueda.length > 0) {
      guardarDrivesFinal(resultadoBusqueda);
      console.log("BusquedaEstados");
    } else {
      resultadoBusqueda = drivesFinal.filter((municipio) => municipio.nombre === busqueda);
      if (resultadoBusqueda.length > 0) {
        guardarDrivesFinal(resultadoBusqueda);
        console.log("BusquedaMunicipios");
      } else {
        resultadoBusquedaDrive = drives.filter((drive) => drive.nombre_drive === busqueda);
        if (resultadoBusquedaDrive.length > 0) {
          console.log("BusquedaDrives");
          guardarBdrives(resultadoBusquedaDrive);
        } else {
          guardarDrivesFinal(municipiosrespaldo);
          console.log("TodosMunicipios");
        }
      }
    }
  };

  // const MarkerIcon = (icon) => {
  //   const Icono = L.icon({
  //     iconUrl: icon,
  //     shadowUrl: iconShadow,
  //   });
  //   return Icono;
  // }

  const removeAccents = (str) => {
    const text = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return text.charAt(0).toUpperCase() + text.slice(1);

  }


  const busca = (e) => {
    e.preventDefault();
    let filtro1 = municipios.filter((item) => removeAccents(item.nombre).indexOf(removeAccents(e.target.value)) > -1)
    if (filtro1.length > 0) {
      guardarDrivesFinal(filtro1)
    } else {
      // console.log('que paso ');
      // let filtro2
      // for (let i = 0; i < municipios.length; i++) {
      //   console.log('FOR ', municipios[i].drives);
      //   filtro2 = municipios[i].drives.filter((item) => removeAccents(item.DriveName).indexOf(removeAccents(e.target.value)) > -1)
      //   console.log('filtro2 ', filtro2)
      // }
      guardarDrivesFinal(municipios)

    }
  }
  console.log('drivesFinal ', drivesFinal);

  return (
    <>
      <div>
        {coordenadas.length > 0 ? (
          <MapContainer center={coordenadas} zoom={zoom} whenCreated={guardarMapa}>
            <TileLayer url={URL} attribution={ATTRIBUTION} />
            {ubicaciones.map((drive, index) => (
              <Marker key={index} position={[drive.Latitude, drive.Longitude]} icon={MarkerIcon}>
                <Popup>
                  <b>{drive.DriveName}</b>
                  <br />
                  CAFFENIO
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        ) : (
          <div>
            <h1>ERROR</h1>
            {coordenadas.length}
          </div>
        )}

        <div className="caja-buscar">
          <input
            name="buscar"
            placeholder="Buscar ubicación"
            onChange={busca}
          />
          <img
            // src="../wp-content/uploads/2022/02/ubicacion.png"
            src="https://drive.caffenio.com/wp-content/uploads/sites/2/2022/12/ubicacion.png"
            alt=""
            className="btnBuscar"
            onClick={buscar}
          />
        </div>

        <div className="menu-ciudades">
          <label>Ubicaciones</label>
          <div>
            {
              drivesFinal.length == 0 ?
                ((
                  <Menu
                    guardarCoordenadas={guardarCoordenadas}
                    ubicaciones={municipios}
                    ChangeView={ChangeView}
                  />
                )) :
                (
                  <Menu
                    guardarCoordenadas={guardarCoordenadas}
                    ubicaciones={drivesFinal}
                    ChangeView={ChangeView}
                  />
                )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
