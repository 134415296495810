import React, { useState, useEffect } from "react";
import { clienteAxiosReyes } from "../../Utils/axios.js";
import { Decrypt, Encrypt } from "../../Utils/EncryptDecrypt.js";
import "../components/Error.css";

const Nombre = () => {
  const [usuarioExistente, guardarUsuarioExistente] = useState("");

  useEffect(() => {
    const verificarToken = async () => {
      const usuario = Decrypt("usuario");
      const { Nombre } = usuario ?? {};

      if (!Nombre) {
        guardarUsuarioExistente('');

        const currentURL = window.location.href;
        if (currentURL.includes("/historial") || currentURL.includes("/felicidades")) {
          setTimeout(function() {
            // Coloca aquí el código que deseas ejecutar después de 5 segundos
            // console.log("pasó por aquí jsdjaskdjaskd")
            window.location.href = "https://caffenio.com/reyesdecaffenio";
          }, 5000);
          
        }

      } else {
        guardarUsuarioExistente(Nombre);
      }
    }
    verificarToken();
  }, []);


  const cerrarSesion = () => {
    window.sessionStorage.clear();
    window.location.href = "https://caffenio.com/reyesdecaffenio/";
  }

  return (
    <>
      {usuarioExistente != null && usuarioExistente != '' ?
        <div className="container-login">
          <h2 id="nombre">¡Hola, {usuarioExistente}!</h2>
          <a className="mispuntoslink" href="https://caffenio.com/reyesdecaffenio/historial">Mis puntos</a>
          <p className="cerrar-sesión" id="cerrarSesion" onClick={cerrarSesion}>Cerrar sesión</p>

        </div>
        : ''}
    </>
  )
}

export default Nombre;