import React, { useState, useEffect } from "react";
import clienteAxios from "../config/axios";
import { Decrypt, Encrypt } from "../Utils/EncryptDecrypt";
import Producto from "./Producto";
import SaborImagenes from "./SaborImagenes";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './Productos.css'

const Clasificacion = ({ productos, categorias, etiquetas }) => {
  const [categoriesName, setCategoryName] = useState('RECOMENDADO');
  const [tagName, setTagName] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(616);
  const [selectedTagyId, setSelectedTagId] = useState(null);
  const [products, setProducts] = useState([]);
  const [changeTitle, setchangeTitle] = useState(false);
  const userName = process.env.REACT_APP_USERNAME;
  const pass = process.env.REACT_APP_PASS;
  const token = Buffer.from(`${userName}:${pass}`, 'utf8').toString('base64');

  const [image, setImage] = useState([]);

  const onClick = (product) => {
    Encrypt('product', product);
    window.location.href = "/detalle-productos";
  };

  useEffect(() => {
    if (selectedCategoryId !== null) {
      clienteAxios(
        `wp-json/wc/v3/products?category=${selectedCategoryId}&per_page=40`,
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
        .then((response) => {
          setProducts(response.data);
          Encrypt('products', response.data);
          imageMedia(response.data);
          setchangeTitle(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (selectedTagyId !== null) {

      clienteAxios(
        `wp-json/wc/v3/products?tag=${selectedTagyId}&per_page=40`,
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
        .then((response) => {
          setProducts(response.data);
          Encrypt('products', response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

  }, [selectedCategoryId, selectedTagyId]);

  const imageMedia = (images) => {

    const aux = [];
    const imagen_web = [];
    for (let i = 0; i < images.length; i++) {
      imagen_web.push(images[i].meta_data.find(item => item.key === "imagen_web"));
      if (imagen_web[i] != undefined) {
        const { value } = imagen_web[i];
        clienteAxios(`wp-json/wp/v2/media/${value}`, {
        }).then((respuesta) => {
          aux.push(respuesta.data.guid.rendered)
          // setImage(respuesta.data.guid.rendered);
          setImage(aux);
        })
          .catch((error) => {
            console.log(error);
          });
      }
    }

  }

  const handleCategoryClick = (categoryId, categoryName) => {
    setSelectedCategoryId(categoryId);
    setCategoryName(categoryName);
    setchangeTitle(true);
    if (selectedTagyId) {
      setSelectedTagId(null);
    }
  };

  const handleTagsClick = (tagId, tagName) => {
    setSelectedTagId(tagId);
    setTagName(tagName);
    setchangeTitle(false);
    if (selectedCategoryId) {
      setSelectedCategoryId(null);
    }
  };

  const settingsTag = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };

  const settingsCel = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  return (

    <div>
      <div className="categorias-container cel-none">
        {categorias.map(category => (
          <a href="#products-section" key={category.id} className={`categorias-box  ${category.id == selectedCategoryId ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category.id, category.name)}>
            <img className="img-category" src={category.image == null ? category.image.src : category.image.src} alt={category.name} />
            <h4 className="name-category">{category.name}</h4>
          </a>
        ))}
      </div>

      <div>
        <div className="mb-10 cel-category-only">
          <Slider {...settingsCel}>
            {categorias.map((category, index) => (
              <a href="#products-section" key={category.id} className=" categorias-box" onClick={() => handleCategoryClick(category.id, category.name)}>
                <img className="img-category" src={category.image == null ? 'https://drive.caffenio.com/wp-content/uploads/sites/2/woocommerce-placeholder.png' : category.image.src} alt={category.name} />
                <h4 className="name-category">{category.name}</h4>
              </a>

            ))}
          </Slider>
        </div>
      </div>

      <div>

        <div className="tags-container">
          <Slider {...settingsTag}>
            {etiquetas.map(tag => (
              <a href="#products-section" key={tag.id} className={`product-slide product-slideCel elementor-animation-grow ${tag.id == selectedTagyId ? 'active' : ''}`} onClick={() => handleTagsClick(tag.id, tag.name)}>
                <span className="link-tag list-name" href="#"> {tag.name} </span>
              </a>
            ))}
          </Slider>
        </div>
      </div>

      <h2 id="products-section" className="category_name">{(changeTitle) ? categoriesName : tagName}</h2>

      <div className="products-container">
        {products.map((product) => (


          <div key={product.id} className="product" onClick={() => onClick(product)}>
            <div className="cel-wd-50">
              <img
                // 
                src={product.image_web == "" ? product.images[0].src : product.image_web}
                alt={product.name}
                className="product-image"
              />
            </div>
            <div className="cel-wd-50 cel-order1">
              <div className="product-info">
                <h2 className="product-name">{product.name}</h2>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>


  );
};

export default Clasificacion;
