import React, { useState, useEffect } from "react";
import { Decrypt, Encrypt } from "../Utils/EncryptDecrypt";
import clienteAxios from "../config/axios";
import "./Detalleproductos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Detalleproductos = () => {
    const [nutri, setNutri] = useState([]);
    const [newProduct, setNewProduct] = useState([]);
    const [change, setChanche] = useState(false);


    const product = Decrypt('product');
    const listaProductos = Decrypt('products');
    console.log(' productos ', product);
    console.log(' listaProductos ', listaProductos);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);


    if (product == null || product == "") {
        window.location.href = "../menu";
    }

    const handleAccordion1 = () => {
        console.log('() =>  ');
        setIsOpen1(!isOpen1);
    };
    const handleAccordion2 = () => {
        console.log('() =>  ');
        setIsOpen2(!isOpen2);
    };
    const handleAccordion3 = () => {
        console.log('() =>  ');
        setIsOpen3(!isOpen3);
    };
    const handleAccordion4 = () => {
        console.log('() =>  ');
        setIsOpen4(!isOpen4);
    };

    useEffect(() => {
        const obtenerLinkPDF = async () => {
            if (change) {
                const tabla_nutrimental = newProduct.meta_data.find(item => item.key === "tabla_nutrimental");
                if (tabla_nutrimental) {
                    const { value } = tabla_nutrimental;
                    await clienteAxios(`wp-json/wp/v2/media/${value}`, {
                    }).then((respuesta) => {
                        setNutri(respuesta.data.guid.rendered);
                    })
                        .catch((error) => {
                            setNutri(null);
                            console.log(error);
                        });
                }
            } else {
                const tabla_nutrimental = product.meta_data.find(item => item.key === "tabla_nutrimental");
                if (tabla_nutrimental) {
                    const { value } = tabla_nutrimental;
                    await clienteAxios(`wp-json/wp/v2/media/${value}`, {
                    }).then((respuesta) => {
                        setNutri(respuesta.data.guid.rendered);
                    })
                        .catch((error) => {
                            setNutri(null);
                            console.log(error);
                        });
                }
            }
        };
        obtenerLinkPDF();

    }, [newProduct]);


    const onClick = (productoID) => {
        const newShow = listaProductos.find(item => item.id === productoID);
        setNewProduct(newShow);
        console.log('este cambio ', newShow);
        setChanche(true);
    }

    const settings = {
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: listaProductos.length <= 5 ? listaProductos.length : 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };

    console.log('productos.tags.length ', product.tags.length);
    console.log('productos.tags.length -1 ', product.tags.length - 1);

    return (
        <>

            <h2 className="single-name">{!change ? product.name : newProduct.name}</h2>

            {((nutri != null)) && (
                <div className="btn-nutri cel-none">
                    <a className="link-nutri" href="https://caffenio.com/cafeterias/menu/infnutrimentalmovil/" target="_blanck">
                        <img className="img-nutri" src="https://caffenio.com/wp-content/uploads/2023/04/wheat-awn-circle-exclamation-solid.png" alt="" />
                        TABLA NUTRIMENTAL
                    </a>
                </div>
            )}

            <div className={`single-container ${(!change && product.sabores.length !== 0) || (change && newProduct.sabores.length !== 0) ? '' : 'column'}`}>
                <div className={`width-30 cel-width-100 ${(!change && product.sabores.length !== 0) || (change && newProduct.sabores.length !== 0) ? '' : 'solo-info'}`}>
                    <h3 className="disfruta">¡DISFRÚTALO COMO QUIERAS!</h3>

                    <img className="img-single" src={!change ? (product.image_web == "" ? product.images[0].src : product.image_web) :
                        (newProduct.image_web == "" ? newProduct.images[0].src : newProduct.image_web)} alt="" />

                    {((!change && product.presentaciones.length !== 0) || (change && newProduct.presentaciones.length !== 0)) && (
                        <>
                            <p className="preparate">PRESENTACIONES</p>
                            <div className="presentations">
                                <div className="bg-presentations">
                                    {(change ? newProduct.presentaciones : product.presentaciones).map((presentacion, index) => (
                                        <div className={`ds-flex-center ${index === (change ? newProduct.presentaciones : product.presentaciones).length - 1 ? '' : 'line-right'}`} key={index}>
                                            <img className="img-presentation" src={presentacion.image} alt={presentacion.name} />
                                            <span className="presentations-list">{presentacion.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <p className="description">{(change ? newProduct.description : product.description).replace(/(<([^>]+)>)/gi, '')}</p>
                        </>
                    )}


                </div>
                <div className="width-70 cel-width-100">
                    <div className="cel-none">

                        {((!change && product.sabores.length !== 0) || (change && newProduct.sabores.length !== 0)) && (
                            <div className="options-list">
                                <h5 className="title-red">SABORES</h5>
                                <div className="ds-flex">
                                    {(change ? newProduct.sabores : product.sabores).map((sabor) => (
                                        <span className="sub-titles">{sabor.name}</span>
                                    ))}
                                </div>
                            </div>
                        )}

                        {((!change && product.sizes.length !== 0) || (change && newProduct.sizes.length !== 0)) && (
                            <div className="options-list">
                                <h5 className="title-red">TAMAÑOS DISPONIBLES</h5>
                                <div className="ds-flex">
                                    {(change ? newProduct.sizes : product.sizes).map((size) => (
                                        <div className="size-glass">
                                            <img className="img-size" src={size.image} alt="" />
                                            <p className="sub-titles">{size.name}</p>
                                            {/* <p className="sub-titles">({size.slug})</p> */}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {((!change && product.leche.length !== 0) || (change && newProduct.leche.length !== 0)) && (
                            <div className="options-list">
                                <h5 className="title-red">LECHES Y BEBIDAS VEGETALES</h5>
                                <div className="ds-flex">
                                    {(change ? newProduct.leche : product.leche).map((leche) => (
                                        <span className="sub-titles" key={leche.name}>{leche.name}</span>
                                    ))}
                                </div>
                            </div>
                        )}

                        {((!change && product.grano.length !== 0) || (change && newProduct.grano.length !== 0)) && (
                            <div className="options-list">
                                <h5 className="title-red">TIPO DE GRANO</h5>
                                <div className="ds-flex">
                                    {(change ? newProduct.grano : product.grano).map((grano) => (
                                        <span className="sub-titles">{grano.name}</span>
                                    ))}
                                </div>
                            </div>
                        )}

                    </div>
                    {/* acordeon mobil */}
                    <div className="cel-only">


                        {((!change && product.sizes.length !== 0) || (change && newProduct.sizes.length !== 0)) && (
                            <div className="accordion">
                                <div className="accordion-header" onClick={() => handleAccordion1()}>
                                    <h3 className="accordion-title">TAMAÑOS</h3>
                                    <img className="icon-arrow" src={isOpen1 ? 'https://drive.caffenio.com/wp-content/uploads/sites/2/2023/04/Down.png' : 'https://drive.caffenio.com/wp-content/uploads/sites/2/2023/04/upload.png'} alt="Flecha" />
                                </div>
                                {isOpen1 && (

                                    <div className="accordion-content ds-flex">
                                        {(change ? newProduct.sizes : product.sizes).map((size) => (
                                            <div className="size-glass">
                                                <img className="img-size" src={size.image} alt="" />
                                                <p className="sub-titles">{size.name}</p>
                                                {/* <p className="sub-titles">({size.slug})</p> */}
                                            </div>
                                        ))}

                                    </div>
                                )}
                            </div>
                        )}

                        {((!change && product.leche.length !== 0) || (change && newProduct.leche.length !== 0)) && (
                            <div className="accordion">
                                <div className="accordion-header" onClick={() => handleAccordion2()}>
                                    <h3 className="accordion-title">LECHES Y BEBIDAS VEGETALES</h3>
                                    <img className="icon-arrow" src={isOpen2 ? 'https://drive.caffenio.com/wp-content/uploads/sites/2/2023/04/Down.png' : 'https://drive.caffenio.com/wp-content/uploads/sites/2/2023/04/upload.png'} alt="Flecha" />
                                </div>
                                {isOpen2 && (
                                    <div className="accordion-content ds-flex">
                                        {(change ? newProduct.leche : product.leche).map((leche) => (
                                            <span key={leche.id} className="sub-titles">{leche.name}</span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                        {((!change && product.sabores.length !== 0) || (change && newProduct.sabores.length !== 0)) && (
                            <div className="accordion">
                                <div className="accordion-header" onClick={() => handleAccordion3()}>
                                    <h3 className="accordion-title">SABORES</h3>
                                    <img className="icon-arrow" src={isOpen3 ? 'https://drive.caffenio.com/wp-content/uploads/sites/2/2023/04/Down.png' : 'https://drive.caffenio.com/wp-content/uploads/sites/2/2023/04/upload.png'} alt="Flecha" />
                                </div>
                                {isOpen3 && (
                                    <div className="accordion-content ds-flex">
                                        {(change ? newProduct.sabores : product.sabores).map((sabores) => (
                                            <span key={sabores.id} className="sub-titles">{sabores.name}</span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                        {((!change && product.grano.length !== 0) || (change && newProduct.grano.length !== 0)) && (
                            <div className="accordion">
                                <div className="accordion-header" onClick={() => handleAccordion4()}>
                                    <h3 className="accordion-title">TIPO DE GRANO</h3>
                                    <img className="icon-arrow" src={isOpen4 ? 'https://drive.caffenio.com/wp-content/uploads/sites/2/2023/04/Down.png' : 'https://drive.caffenio.com/wp-content/uploads/sites/2/2023/04/upload.png'} alt="Flecha" />
                                </div>
                                {isOpen4 && (
                                    <div className="accordion-content ds-flex">
                                        {(change ? newProduct.grano : product.grano).map((grano) => (
                                            <span key={grano.id} className="sub-titles">{grano.name}</span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}



                    </div>

                    {/* fin acordeon mobil */}

                </div>
            </div>

            <div className="btn-nutri cel-only-flex">
                <a className="link-nutri" href="https://caffenio.com/cafeterias/menu/infnutrimentalmovil/" target="_blanck">
                    <img className="img-nutri" src="https://caffenio.com/wp-content/uploads/2023/04/wheat-awn-circle-exclamation-solid.png" alt="" />
                    TABLA NUTRIMENTAL
                </a>
            </div>

            <div className="mb-10">
                <h2 className="disfruta">
                    ¡DESCUBRE OTROS PRODUCTOS!
                </h2>
                <Slider {...settings}>
                    {listaProductos.map((producto, index) => (
                        !change ? (

                            <div className={`product-slide ${producto.id == product.id ? 'active' : ''}`} key={producto.id} onClick={() => onClick(producto.id)}>
                                <h3 className={`list-name ${producto.id == product.id ? 'active' : ''}`}  >{producto.name}</h3>
                            </div>
                        ) : (

                            <div className={`product-slide ${producto.id == newProduct.id ? 'active' : ''}`} key={producto.id} onClick={() => onClick(producto.id)}>
                                <h3 className={`list-name ${producto.id == newProduct.id ? 'active' : ''}`}>{producto.name}</h3>
                            </div>
                        )
                    ))}
                </Slider>
            </div>
        </>

    )
}

export default Detalleproductos;