import React from "react";
import { clienteAxiosReyes } from "../../Utils/axios.js";
import { Decrypt, Encrypt } from "../../Utils/EncryptDecrypt.js";
import "./Error.css";

const AgregarParticipantes = ({ mensaje, guardarConfirmar }) => {

    const agregarConcursante = async () => {
        let data = {};
        await clienteAxiosReyes
            .post("/api/reyes/2023/AddProgram?check_data=0", data, {
                headers: {
                    Authorization: "Bearer " + Decrypt("token"),
                },
            })
            .then((respuesta) => {
                window.location.href = "felicidades/";
            })
            .catch((error) => {
                console.log(
                    "No puede iniciar sesión con las credenciales proporcionadas."
                );
            });
    };

    const cerrar = () => {
        guardarConfirmar(false);
    };

    return (
        <>

            <div className="error-contenedor">
                <div className="error-mensaje">{mensaje}</div>
                <div className="error-contenedor-boton">
                    <input
                        type="button"
                        value="No, regresar"
                        onClick={cerrar}
                        className="error-boton"
                    />
                    <input
                        type="button"
                        value="Sí, quiero participar"
                        onClick={agregarConcursante}
                        className="error-boton aceptar"
                    />

                </div>
            </div>

        </>
    );
};

export default AgregarParticipantes;
