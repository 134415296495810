import React from 'react';

const Cafeterias = ({ cafeterias, showModal, changeView }) => {
  return (
    <>
      <div className="row bgcolorcafeterias height-750px espaciado-cafeteria justify-content-between align-content-start width-cafeterias">
        {cafeterias.map((cafeteria) => (
          <div className="col-lg-6 col-sm-12 infocafeteria mt-2vh espaciado-cafeteria-info maxWidth">
            <a
              href="#!"
              onClick={() =>
                changeView([cafeteria.latitude, cafeteria.longitud])
              }
            >
              <div className="titulo">{cafeteria.title}</div>
              <div className="direccion">{cafeteria.direccion}</div>
              <div className="row pl-1vw justify-content-between">
                <a
                  href="#!"
                  onClick={() => showModal(cafeteria)}
                  className="col-lg-6 btn-info-cafeterias btn-detalles maxWidth"
                >
                  DETALLES
                </a>
                <a
                  href={`https://www.google.com/maps/search/${cafeteria.latitude},${cafeteria.longitud}`}
                  className="col-lg-6 btn-info-cafeterias btn-llegar maxWidth"
                  target="_blank"
                  rel="noreferrer"
                >
                  COMO LLEGAR
                </a>
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default Cafeterias;
