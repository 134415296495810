import React from 'react';
import { useMapEvents } from 'react-leaflet';
const ColocarPosicion = ({ coordenadas, guardarCoordenadas }) => {
  const map = useMapEvents({
    click: () => {
      map.flyTo(coordenadas, 18);
    },
  });

  return <h1>Hola</h1>;
};

export default ColocarPosicion;
